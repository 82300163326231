<template>
  <div class="create-terms-page">
    <BackTitle
      :class="['xs', 'sm'].includes($mq) ? 'mb-40' : 'mb-64'"
      confirm-click
      @click="goBack"
    >
      Create terms & conditions
    </BackTitle>
    <TermsForm
      :value="terms"
      :is-submitting="isSubmitting"
      data-v-step="4"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle";
import TermsForm from "@/components/terms/TermsForm";
import { NEW_TERMS_MOCK } from "@/helpers/mocks";
import { mapActions } from "vuex";

export default {
  name: "CreateTermsPage",
  components: { TermsForm, BackTitle },
  data() {
    return {
      isSubmitting: false,
      terms: {
        ...NEW_TERMS_MOCK,
      },
    };
  },
  methods: {
    ...mapActions({
      createTerms: "terms/createTerms",
    }),
    goBack() {
      this.$router.push({
        name: "Terms",
      });
    },
    async handleSubmit(data) {
      try {
        this.isSubmitting = true;
        await this.createTerms(data);
        this.goBack();
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.create-terms-page {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
